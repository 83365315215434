import { useEffect, useState } from 'react';

export const useLockBodyScroll = (isVisible) => {
  const [lock, setLock] = useState(isVisible);
  useEffect(() => {
    if (!window || !document) return () => null;

    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (lock) {
      document.body.style.overflow = 'hidden';
    }

    return () => (document.body.style.overflow = originalStyle);
  }, [lock]);

  return [lock, setLock];
};
