import React, { FC } from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';

export const IconMenuChevron: FC<PropsWithClassProps> = ({ classProps }) => {
  return (
    <svg className={classProps?.root} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.25 1.125L5 4.875L8.75 1.125"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
