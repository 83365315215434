import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { IconClose } from '@assets/icon-close';
import { IconSearch } from '@assets/icon-search';
import { HeaderButton } from '../HeaderButton/HeaderButton';

export const SearchButton = () => {
  const [isActive, setIsActive] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (router.asPath.startsWith('/suche')) {
      setIsActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <HeaderButton
        link={!isActive && { title: '', href: '/suche' }}
        onClick={isActive ? () => router.back() : undefined}
        classProps={{ root: 'rounded-full w-8 md:w-auto grid place-items-center' }}
        ariaLabel="Suche"
      >
        {isActive ? (
          <IconClose classProps={{ root: ' w-2.5 h-2.5 md:w-4 md:h-4' }} />
        ) : (
          <IconSearch classProps={{ root: ' w-2.5 h-2.5 md:w-4 md:h-4' }} />
        )}
      </HeaderButton>
    </div>
  );
};
