import React, { PropsWithChildren } from 'react';
import { useRouter } from 'next/router';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { Link, LinkProps } from '../Link/Link';

export interface HeaderButtonProps extends PropsWithChildren, PropsWithClassProps {
  link?: LinkProps;
  ariaLabel?: string;
  onClick?: () => void;
}

export const HeaderButton = ({ children, onClick, classProps, link, ariaLabel }: HeaderButtonProps) => {
  const router = useRouter();
  const buttonStyle = twMerge(
    'flex h-8 items-center text-[9px] cursor-pointer rounded-full border-0.5 border-gray-650/65 px-2 text-white transition-colors duration-200 hover:border-primary hover:text-primary md:h-12 md:px-4 font-proxima-nova bg-gray-650/65 lg:bg-gray-800/70 tracking-widest font-bold lg:text-base md:text-sm backdrop-blur-sm',
    classProps?.root,
    router.pathname === link?.href && 'bg-primary',
  );
  return link ? (
    <Link
      href={link.href || ''}
      classProps={{ root: buttonStyle }}
      ariaLabel={ariaLabel}
      target={link.target}
      rel={link.rel}
    >
      {children || link.title}
    </Link>
  ) : (
    <div onClick={onClick} className={buttonStyle}>
      {children}
    </div>
  );
};
