import React, { FC } from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';

export const IconSearch: FC<PropsWithClassProps> = ({ classProps }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classProps?.root}
    >
      <path
        d="M11.9569 10.083L16 13.8733L14.2649 15.5L10.2218 11.7097C9.17365 12.3786 7.90732 12.7695 6.54393 12.7695C2.92995 12.7695 0 10.0231 0 6.63491C0 3.24682 2.92988 0.5 6.54393 0.5C10.1579 0.5 13.0876 3.24675 13.0876 6.63491C13.0876 7.91308 12.6705 9.10032 11.957 10.0829L11.9569 10.083ZM6.54393 10.4692C8.80256 10.4692 10.6338 8.75241 10.6338 6.63495C10.6338 4.51722 8.80256 2.80072 6.54393 2.80072C4.28501 2.80072 2.45407 4.51722 2.45407 6.63495C2.45407 8.75241 4.28501 10.4692 6.54393 10.4692Z"
        fill="currentColor"
      />
    </svg>
  );
};
