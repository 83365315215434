import { DropdownProps } from '@vgn-medien-holding/vgn-fe-components';

const classProps = {
  root: '',
  button: 'bg-transparent rounded-full border border-gray-400 text-base text-white px-5 py-2 min-h-[43px]',
  buttonActive: '[&>span]:text-white',
  buttonPlaceholder: 'text-white',
  itemWrapper:
    'bg-gray-820/95 rounded-2xl min-w-32 mt-2 border-[0.5px] border-gray-650/65 scrollbar-dark backdrop-blur-md',
  item: 'py-3 text-white mx-5 text-center border-b border-gray-650/65 last:border-none',
} as DropdownProps['classProps'];

export default classProps;
