import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { MenuItem, PropsWithClassProps } from '@vgn-medien-holding/vgn-react-components';
import { twMerge } from 'tailwind-merge';
import { useEventListener } from '@lib/hooks/useEventListener';
import { useLockBodyScroll } from '@lib/hooks/useLockBodyScroll';
import { IconClose } from '@assets/icon-close';
import { IconMenuChevron } from '@assets/icon-menuChevron';
import { Link } from '../Link/Link';

export interface NavItemProps extends PropsWithClassProps<'default' | 'link'> {
  item: MenuItem;
  type?: string;
  onUpdateHoverPosition?: ({ position, size }: { position?: number; size?: number }) => void;
  onLoadSetActivePosition?: ({ position, size }: { position?: number; size?: number }) => void;
}

export const NavItem = ({
  item,
  type,
  classProps,
  onUpdateHoverPosition = () => null,
  onLoadSetActivePosition = () => null,
}: NavItemProps) => {
  const router = useRouter();
  const linkContainer = useRef(null);
  const linkUrl = item.slug || item.url;
  const [isOpen, setOpen] = useLockBodyScroll(false);
  const [active, setActive] = useState(
    (linkUrl === '/' && router.asPath === '/') || (linkUrl !== '/' && router.asPath.startsWith(linkUrl)),
  );
  const styles = {
    default: twMerge(
      'font-herokid self-center rounded-full inline-flex leading-[13px] text-white px-1.5 p-2.5 lg:p-3 duration-150 group lg:leading-[17px] whitespace-nowrap',
      classProps?.default,
    ),
    sub: twMerge(
      'uppercase tracking-wide py-0.5 font-herokid font-medium text-white hover:underline decoration-2 decoration-primary underline-offset-4',
      active && 'underline',
    ),
  };

  const sendActivePosition = () => {
    const box = linkContainer?.current?.getBoundingClientRect();
    onLoadSetActivePosition({ position: box.left, size: box.width });
  };

  router?.events?.on('routeChangeComplete', () => {
    setActive((linkUrl === '/' && router.asPath === '/') || (linkUrl !== '/' && router.asPath.startsWith(linkUrl)));
    setOpen(false);
  });

  useEffect(() => {
    if (!document?.fonts) return;
    document.fonts.ready.then(() => {
      const box = linkContainer?.current?.getBoundingClientRect();
      if (box?.width < 1 || !router || !active) return;
      onLoadSetActivePosition({ position: box.left, size: box.width });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkContainer.current, active]);

  useEventListener('resize', () => {
    const box = linkContainer?.current?.getBoundingClientRect();
    if (box?.width < 1 || !router || !active) return;
    onLoadSetActivePosition({ position: box.left, size: box.width });
  });

  const handleMouseOver = () => {
    const box = linkContainer.current.getBoundingClientRect();
    onUpdateHoverPosition({ position: box.left, size: box.width });
  };

  return (
    <>
      {item.children && item.children.length > 0 ? (
        <div
          className={twMerge('group relative inline-block cursor-pointer text-left', classProps?.root)}
          ref={linkContainer}
        >
          <div onMouseOver={handleMouseOver}>
            <Link href={linkUrl || ''}>
              <div
                className={twMerge(
                  'hidden items-center gap-2 self-center rounded-full text-white transition-colors duration-150 sm:flex lg:p-3',
                  type === 'sub' ? styles.sub : styles.default,
                )}
                onClick={(evt) => {
                  if (evt.ctrlKey || evt.shiftKey || evt.metaKey || (evt.button && evt.button == 1)) {
                    return;
                  }
                  sendActivePosition();
                }}
              >
                {item.title}
                <IconMenuChevron classProps={{ root: type === 'sub' ? 'w-2' : 'w-3' }} aria-hidden="true" />
              </div>
            </Link>
            <div
              className={twMerge(
                'flex items-center gap-2 self-center rounded-full text-white transition-colors duration-150 sm:hidden lg:p-3',
                type === 'sub' ? styles.sub : styles.default,
              )}
            >
              {item.title}
              <IconMenuChevron classProps={{ root: type === 'sub' ? 'w-2' : 'w-3' }} aria-hidden="true" />
            </div>
          </div>
          <div
            className={twMerge(
              'pointer-events-none hidden opacity-0 transition-opacity duration-200 lg:block lg:group-hover:pointer-events-auto lg:group-hover:opacity-100',
            )}
          >
            <div className="absolute -right-7 w-44 origin-top-right pt-3">
              <div className="flex flex-col content-center items-center divide-y divide-gray-650/65 rounded-xl border border-gray-600/65 bg-gray-820/70 backdrop-blur-sm">
                {item.children.map((menuitem, idx) => (
                  <div key={idx}>
                    <Link
                      href={menuitem.url || ''}
                      classProps={{
                        root: 'mx-6 inline-block py-4 text-center font-proxima-nova leading-small text-white decoration-primary decoration-2 underline-offset-4 hover:underline',
                      }}
                    >
                      {menuitem.title}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className="absolute inset-0 lg:hidden"
            onClick={() => {
              setOpen(true);
            }}
          ></div>
          <div
            className={twMerge(
              'pointer-events-none fixed inset-0 z-40 bg-black p-4 opacity-0 transition-opacity delay-75 duration-300',
              isOpen && 'pointer-events-auto opacity-100',
            )}
          >
            <div className="text-right">
              <div
                className="ml-auto inline-block"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <IconClose
                  classProps={{
                    root: 'w-8 h-8 border border-gray-650/25 bg-gray-600 p-2 rounded-full',
                  }}
                />
              </div>
            </div>
            <div className="absolute left-1/2 top-0 grid min-h-full -translate-x-1/2 content-center items-center text-center font-herokid text-xl font-medium text-white">
              {item.children.map((menuitem, idx) => (
                <div key={idx}>
                  <Link
                    href={menuitem.url || ''}
                    classProps={{
                      root: 'inline-block py-4 decoration-primary decoration-2 underline-offset-4 hover:underline',
                    }}
                  >
                    {menuitem.title}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <a
          href={linkUrl || ''}
          target={item.target}
          rel="noreferrer"
          onMouseOver={handleMouseOver}
          className={twMerge(type === 'sub' ? styles.sub : styles.default, classProps?.root)}
          ref={linkContainer}
          onClick={(evt) => {
            if (evt.ctrlKey || evt.shiftKey || evt.metaKey || (evt.button && evt.button == 1)) {
              return;
            }
            sendActivePosition();
          }}
        >
          {item.title}
        </a>
      )}
    </>
  );
};
