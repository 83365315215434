import React, { useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { NavItem } from '@components/atoms/NavItem/NavItem';
import AppContext from '@lib/AppContext';

export function HeaderNav({ classProps }: PropsWithClassProps) {
  const context = useContext(AppContext);
  const router = useRouter();
  const [activePosition, setActivePosition] = useState(null);
  const [mobileActivePosition, setMobileActivePosition] = useState(null);
  const [hoverInfos, setHoverInfos] = useState(null);
  const [mobileHoverInfos, setMobileHoverInfos] = useState(null);
  const [navLoading, setNavLoading] = useState(false);
  const [bgVisible, setBgVisible] = useState(false);
  const navContainer = useRef(null);
  const mobileNavContainer = useRef(null);
  const { header } = context;
  const mobileNavItems = header?.navigation?.items?.slice(0, 2);

  const rootStyle = twMerge('text-center pointer-events-auto content-between grid lg:content-center', classProps?.root);

  const resetNavPosition = () => {
    if (!activePosition && !mobileActivePosition) {
      return setBgVisible(false);
    }
    setHoverInfos(activePosition);
    setMobileHoverInfos(mobileActivePosition);
  };

  useEffect(() => {
    if (activePosition || mobileActivePosition) {
      setBgVisible(true);
    }
  }, [activePosition, mobileActivePosition]);

  useEffect(() => {
    const handleRouteChangeStart = (url, { shallow }) => {
      if (!shallow) setNavLoading(true);
    };
    const handleRouteChangeComplete = () => {
      setNavLoading(false);
    };

    router?.events?.on('routeChangeStart', handleRouteChangeStart);
    router?.events?.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router?.events?.off('routeChangeStart', handleRouteChangeStart);
      router?.events?.off('routeChangeComplete', handleRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <nav className={rootStyle} ref={navContainer} onMouseLeave={resetNavPosition}>
      {/* >=992px */}
      <div
        className={
          'relative hidden items-center gap-2 rounded-full border-0.5 border-gray-600/65 bg-gray-800/70 p-1.5 font-herokid text-[11px] font-bold backdrop-blur-sm lg:flex lg:text-[15px]'
        }
      >
        {bgVisible && (
          <div
            className={twMerge(
              'absolute inset-y-1.5 w-96 rounded-full bg-primary opacity-0',
              bgVisible && 'opacity-100',
              navLoading && 'animate-pulse',
              'transition-all duration-300',
            )}
            style={{
              left: `${hoverInfos?.position - (navContainer?.current?.offsetLeft || 0)}px`,
              width: `${hoverInfos?.size}px`,
            }}
          ></div>
        )}

        <div className="relative">
          {header?.navigation?.items?.map((item, index) => (
            <NavItem
              item={item}
              key={index}
              onLoadSetActivePosition={(infos) => {
                setActivePosition(infos);
                setHoverInfos(infos);
              }}
              onUpdateHoverPosition={(infos) => {
                setHoverInfos(infos);
                setBgVisible(true);
              }}
            />
          ))}
        </div>
      </div>

      {/* <992px */}
      <div
        className={
          'relative mx-auto inline-flex items-center rounded-full border-0.5 border-gray-600/65 bg-gray-800/70 p-1.5 text-[11px] font-bold lg:hidden'
        }
        ref={mobileNavContainer}
      >
        {bgVisible && (mobileActivePosition || mobileHoverInfos) && (
          <div
            className={twMerge(
              'absolute inset-y-1.5 w-96 rounded-full bg-primary opacity-0 transition-all duration-300',
              bgVisible && (mobileActivePosition || mobileHoverInfos) && 'opacity-100',
              navLoading && 'animate-pulse',
            )}
            style={{
              left: `${mobileHoverInfos?.position - (mobileNavContainer?.current?.offsetLeft || 0)}px`,
              width: `${mobileHoverInfos?.size}px`,
            }}
          ></div>
        )}
        {mobileNavItems?.map((item, index) => (
          <NavItem
            classProps={{ default: 'relative' }}
            item={item}
            key={index + 'mobile'}
            onLoadSetActivePosition={(infos) => {
              setMobileActivePosition(infos);
              setMobileHoverInfos(infos);
            }}
            onUpdateHoverPosition={(infos) => {
              setMobileHoverInfos(infos);
              setBgVisible(true);
            }}
          />
        ))}
      </div>
    </nav>
  );
}
